import React from 'react'
import classNames from 'classnames';
import { tawreedTranslationService } from '../../../../translations';
import { useFormContext } from 'react-hook-form';

export interface TawreedFormFieldProps {

    /**
     * title
     */
    title: string;

    /**
     * name/htmlFor
     */
    name?: string;

    hint?: string;

    /**
     * className
     */
    className?: string;

    /**
     * children
     */
    children?: React.ReactElement;

    flip?: boolean;

    hide?: boolean;
}

export const TawreedFormField: React.FC<TawreedFormFieldProps> = ({ name, title, hint,className, children, flip, hide }) => {

    const form = useFormContext();


    const getErrorMessage = () => {
        if (!name) {
            return undefined;
        }
        let errs = form?.formState?.errors as any

        try {
            name.split('.').forEach(e => errs = errs[e]);
        } catch {
            return undefined;
        }
        return <small className="p-error">{tawreedTranslationService.translate(errs?.message ?? '')}</small>
    }
    if (hide)
        return <></>
    return (
        <div className={`field-tawreed ${className}`} id={'form-field-' + name}>
            {
                flip ?
                    <React.Fragment>
                        {children}
                        <label htmlFor={name} className={classNames({ 'p-error': false })}>{tawreedTranslationService.translate(title)}</label>
                    </React.Fragment>

                    :

                    <React.Fragment>
                        <label htmlFor={name} className={classNames({ 'p-error': false })}>{tawreedTranslationService.translate(title)}</label>
                        {hint && <span>{hint}</span>}
                        {children}
                    </React.Fragment>
            }
            {getErrorMessage()}
        </div>
    );
}
