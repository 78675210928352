import React, { FC, useState, useRef, useMemo, useEffect } from "react";
import { TawreedCalendar, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputSwitch, TawreedInputText, TawreedInputTextarea, TawreedMultiSelect } from "../../../../common/components/form";
import { SalesOfferDetailsDto } from "../../domain/dtos/sales-offer-details";
import { tawreedTranslationService } from '../../../../common/translations';
import { Panel } from "primereact/panel";
import { GlobalContext } from "../../../../context";
import { PartnerAutoCompleteComponent } from "../../../partners";
import { PartnerType } from "../../../partners/data";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { PrimeIcons } from "primereact/api";
import { SalesOfferItemTable } from "./sales-offer-item-table";
import { priceListDto } from "../../../catalog/store-products/domain";
import { PriceListServcie } from "../../../catalog/store-products/domain/services/price-list.service";
import { SalesOfferService } from "../../domain/services";
import { toastService } from "../../../../modules/notifications/domain";
import { useHistory, useParams } from "react-router-dom";
import { SalesOfferItemDto } from "../../domain/dtos/sales-offer-item";
import { Routes } from "../../../../router"
import { SummaryDto } from "../../domain/dtos/summary";
import { SalesOfferSummary } from "./sales-offer-summary";

export const SalesOfferForm: FC = () => {
    const [data, setData] = useState<SalesOfferDetailsDto>({
        active: true,
        priceListIds: [],
        items: [],
        siteId: 1,
        storeId: undefined,
        salesOfferId: undefined,
        title: undefined,
        description: undefined,
        expirationDate: undefined
    });
    const [offerItems, setOfferItems] = useState<Array<SalesOfferItemDto>>([]);

    const [actions, setActions] = React.useState<TawreedAction[]>([]);

    const ref = useRef<{ submit: () => void }>();
    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);
    const history = useHistory();
    const [mode, setMode] = React.useState<TawreedFormMode>("Create");
    const [priceLists, setPriceLists] = useState<Array<priceListDto>>([]);

    const [loading, setLoading] = React.useState<boolean>(true);

    const [summary, setSummary] = React.useState<SummaryDto>();

    const priceListService: PriceListServcie = useMemo(() => new PriceListServcie(), []);

    const service: SalesOfferService = useMemo(() => new SalesOfferService(), []);
    const { id } = useParams<{ id: string | undefined }>();

    useEffect(() => {
        let mounted = true;
        if (id) {

            if (mounted) {
                setMode("Edit");
            }
            service
                .getDetails(Number.parseInt(id))
                .then((res) => {
                    setData(res);
                    setLoading(false);
                    console.log("res.items", res.items);
                    setOfferItems(res.items ? res.items : []);
                })
                .catch(() => {
                    setLoading(false);
                });

        } else {
            if (mounted) {
                setMode("Create");
                priceListService.getAll().then((res) => {
                    setPriceLists(res);
                    setLoading(false);
                });
            }
        }
        if (mounted) {
            priceListService.getAll().then((res) => {
                setPriceLists(res);
            });
        }
        if (user?.roleCode === 'STORE_ADMIN')
            setData({ ...data, storeId: user.partnerId })

        return () => {
            mounted = false;
        };
    }, [id]);

    const addSalesOfferItem = (salesOfferItem: {
        salesOfferItemId: number; salesOfferId: number; storeProductId: number; storeProductName?: string;
        quantity: number; price: number; discountType: string; discountValue: number; tax: number; sellingPrice: number, subTotal: number
    }) => {
        let items = offerItems;
        if (items?.filter(x => x.storeProductId === salesOfferItem.storeProductId).length == 0) {
            items?.push({ ...salesOfferItem, title: salesOfferItem.storeProductName});
            setOfferItems([...items]);
        }
        else {
            toastService.show({ detail: tawreedTranslationService.translate('lbl_sales_offer_items_exists_error'), severity: 'error' });

        }
    }
    const deleteSalesOfferItem = (storeProductId: number) => {
        let items = offerItems;
        setOfferItems(items?.filter(x => x.storeProductId !== storeProductId));
    }
    useEffect(() => {
        service.calcSummary(offerItems)
            .then((res) => {
                setSummary(res);
            })
            .catch(() => {
            });
    }, [offerItems]);


    const onCreateOrSave = async (data: SalesOfferDetailsDto, cb: TawreedFormSubmitCallback<SalesOfferDetailsDto>): Promise<void> => {
        if (offerItems?.length === 0) {
            toastService.show({ detail: tawreedTranslationService.translate('lbl_sales_offer_items_error'), severity: 'error' });
            return new Promise<void>((resolve, reject) => {
                reject();
            });
        }
        setLoading(true);
        data.expirationDate?.setTime(data.expirationDate.getTime() + (3*60*60*1000));
        service.create(data, offerItems).then((res) => {
            setLoading(false);
            history.replace(Routes.SalesOfferDetails.replace(':id', res.toString()));
        })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    };
    const onSubmit: TawreedFormSubmitProps<SalesOfferDetailsDto> = {
        showButton: (!loading && data.salesOfferId) ? false : true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<SalesOfferDetailsDto>(TawreedActionName.StoreProductsSubmit, "lbl_save", PrimeIcons.SAVE, onCreateOrSave),
    };


    React.useEffect(
        () => {
            const onDelete = () => {
                return service.deleteAll([data!.salesOfferId!]).then(res => {
                    setLoading(false);
                    history.replace(Routes.SalesOfferSearch);
                }).catch(err => {
                    setLoading(false);
                    throw err;
                });
            }
            const onDeactivate = () => {
                return service.deactivate(data!.salesOfferId!).then(res => {
                    setLoading(false);
                    if (res) {
                        setData({ ...data, active: false })
                    }
                }).catch(err => {
                    setLoading(false);
                    throw err;
                });
            }
            const onActivate = () => {
                return service.deactivate(data!.salesOfferId!).then(res => {
                    setLoading(false);
                    if (res) {
                        setData({ ...data, active: true })
                    }
                }).catch(err => {
                    setLoading(false);
                    throw err;
                });
            }
            const next = [];
            if (data && data.salesOfferId) {
                next.push(new TawreedAction(TawreedActionName.StoreProductsDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', true));
                if (!data.active) {
                    next.push(
                        new TawreedAction(TawreedActionName.StoreProductsActivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_activate'), PrimeIcons.CHECK, onActivate, 'p-button-primary', 'confirm')
                    );
                }
                else {
                    next.push(
                        new TawreedAction(TawreedActionName.StoreProductsDeactivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_deactivate'), PrimeIcons.CHECK, onDeactivate, 'p-button-primary', 'confirm')
                    );
                }
            }
            setActions(next);
        },
        [data, service, history]);

    return (
        <React.Fragment>
            <TawreedForm customeTitle={data.title ? data.title : ''} ref={ref}
                title="lbl_sales_offer_header" dataKey="salesOfferId" data={data} submit={onSubmit}
                loading={loading} mode={mode} useBack=".." actions={actions} className="grid form-grid">
                <Panel header={tawreedTranslationService.translate("lbl_general_information")} className="col-12">
                    <div className="grid">

                        {user?.roleCode !== 'STORE_ADMIN' &&
                            <React.Fragment>
                                <TawreedFormField name="store" title="lbl_store_name" className="field col-12 md:col-12">
                                    <PartnerAutoCompleteComponent
                                        partnerType={PartnerType.Store}
                                        autoCompleteName="store"
                                        autoCompleteField="partnerName"
                                        autoCompleteRules={{ required: "msg_field_is_required" }}
                                        autoCompleteDisabled={data.storeId != null}
                                        autoCompleteOnSelect={(e) => {
                                            setData({ ...data, storeId: e.value.partnerId, store: e.value });
                                        }}
                                        autoCompleteRender="form" />
                                </TawreedFormField>
                            </React.Fragment>

                        }
                        <TawreedFormField name="title" title="lbl_sales_offer_title" className="field col-12 md:col-6">
                            <TawreedInputText name="title" rules={{ required: "msg_field_is_required" }} render="form" />
                        </TawreedFormField>
                        <TawreedFormField name="active" title="lbl_sales_offer_active" className="field-checkbox col-12 md:col-6" flip>
                            <TawreedInputSwitch name="active" render="form" />
                        </TawreedFormField>

                        <TawreedFormField name="priceListIds" className="field col-6" title="lbl_price_scheme_price_list_name">
                            <TawreedMultiSelect options={priceLists} name="priceListIds"
                                rules={{ required: "msg_field_is_required" }}
                                optionLabel="priceListName" optionValue="priceListId" render="form" />

                        </TawreedFormField>
                        <TawreedFormField name="expirationDate" title="lbl_sales_offer_expire_date" className="field col-12 md:col-6">
                            <TawreedCalendar minDate={new Date()} render="form" name="expirationDate" rules={{ required: 'msg_field_is_required' }}
                            />
                        </TawreedFormField>
                        <TawreedFormField name="description" title="lbl_sales_offer_description" className="field col-12 md:col-12">
                            <TawreedInputTextarea name="description" rules={{ required: "msg_field_is_required" }}
                                render="form" />
                        </TawreedFormField>

                        {
                            data.storeId &&
                            <SalesOfferItemTable data={offerItems} addSalesOfferItem={addSalesOfferItem} salesOfferId={data.salesOfferId}
                                deleteSalesOfferItem={deleteSalesOfferItem} title={"lbl_sales_offer_items"} store={data.storeId}></SalesOfferItemTable>
                        }
                        {
                            data.storeId && summary &&
                            <SalesOfferSummary summary={summary} />
                        }

                    </div>
                </Panel>

            </TawreedForm>
        </React.Fragment>
    );

}