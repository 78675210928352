import { buildFilterMetaToObject, buildSortParamsToString, PaginatedRequestParams, PaginatedResponse, Result } from "../../../../../common/pagination";
import { tawreedHttpService } from "../../../../../http";
import { endpoints } from "../../../../../constants/endpoints";
import { ProductDto, ProductMatchDto } from "../../domain";
import { ProductMapper, ProductMatchMapper } from "../mappers";
import { JObject } from "../../../../../common/data/models";
import { ProductsFileRepository } from "./products-file.repository";
import axios from "axios";


export class ProductsRepository {

    private filesService = new ProductsFileRepository();

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<ProductDto>>;
    public search(api: 'similar', q: string): Promise<PaginatedResponse<ProductDto>>;

    public search(arg: 'similar' | PaginatedRequestParams, q?: string): Promise<PaginatedResponse<ProductDto>> {
        if (arg === 'similar') {
            const params = { sort: 'display_name,asc', page: 0, size: 25 };
            const data = {
                productName: q?.split('§')[0] ?? '',
                storeId: +(q?.split('§')[1] ?? -1)
            }
            return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.SAERCH_PRODUCTS_SIMILAR, data, params, undefined, 'error')
                .then(res => {
                    return {
                        ...res!,
                        data: { ...res!.data!, content: res!.data!.content.map(e => ProductMapper.toDto(ProductMapper.fromJson(e))) ?? [] },
                    };
                });
        } else {
            const params = {
                sort: arg.sort ? buildSortParamsToString(arg.sort) : undefined,
                page: arg.page,
                size: arg.size,
            };
            const data = { ...buildFilterMetaToObject(arg.keyword, arg.filters) };
            return tawreedHttpService.post<JObject, Result<JObject>>(endpoints.SAERCH_PRODUCTS, data, params, undefined, 'error')
                .then(res => {
                    return {
                        ...res!,
                        data: { ...res!.data!, content: res!.data!.content.map(e => ProductMapper.toDto(ProductMapper.fromJson(e))) ?? [] },
                    };
                });
        }
    }

    /**
     * create or update
     * @param form
     */
    public async createOrUpdate(form: ProductDto): Promise<ProductDto> {
        const data = ProductMapper.toJson(ProductMapper.fromDto(form));
        return tawreedHttpService.post<JObject, JObject>(endpoints.CREATE_OR_SAVE_PRODUCTS, data)
            .then(async (res) => {
                if (res && res.data) {
                    await this.createOrUpdateProductImage(form);
                    return this.getDetails(res.data.productId);
                }
                throw new Error();
            });
    }

    public async createOrUpdateProductImage(form: ProductDto) {
        if (form && form.internationalCode && form.image && form.image.value instanceof File) {
            form.image.value = await this.filesService.upload(form.image, form.internationalCode);
        }
    }

    /**
     * details
     **/
    public getDetails(id: number): Promise<ProductDto> {
        return tawreedHttpService.post<number, JObject>(endpoints.GET_PRODUCT, id, undefined, undefined, 'error').then(res => {
            return this.getProductImage(res.data!.image, res.data!.categoryId).then((image) => {
                return ProductMapper.toDto(ProductMapper.fromJson({ ...res.data!, image: image }))
            })


        });
    }

    /**
     * delete
     * @param id
     */
    public delete(id: number): Promise<boolean> {
        return tawreedHttpService.post<number, number>(endpoints.DELETE_PRODUCTS, id).then(res => {
            return res.status === 200
        });
    }

    /***
     * delete all
     * @param ids
     */
    public deleteAll(ids: number[]): Promise<boolean> {
        return tawreedHttpService.post<number[], number>(endpoints.DELETE_PRODUCTS_ALL, ids).then(res => {
            return res.status === 200
        });
    }
    public changeCategory(ids: number[], categoryId: number): Promise<boolean> {
        return tawreedHttpService.post<JObject, number>(endpoints.CHANGE_PRODUCTS_CATEGORY, {
            productIds: ids,
            categoryId: categoryId,
        }).then(res => {
            return res.status === 200
        });
    }
    public verify(id: number, categoryId: number): Promise<boolean> {
        return tawreedHttpService.post<JObject, number>(endpoints.VERIFY_PRODUCT, { "productId": id, "categoryId": categoryId }).then(res => {
            return res.status === 200
        });
    }
    public link(productId: number, linkTo: number): Promise<boolean> {
        return tawreedHttpService.post<JObject, number>(endpoints.LINK_PRODUCT, { "productId": productId, "linkTo": linkTo }).then(res => {
            return res.status === 200
        });
    }
    public addImage(productId: number, uid: string) {
        const data = {
            productId: productId,
            uid: uid,
        }
        return tawreedHttpService.post<JObject, number>(endpoints.ADD_PRODUCT_IMAGE, data).then(res => res.status === 200)
    }
    public async getProductImage(image: string, categoryId: number) {
        const categoryImage = image.split("/products")[0] + "/categories/" + categoryId.toString();
        return axios.get(image).then((res) => image).catch((er) => {
            if (er.response?.status === 403)
                return categoryImage;
            return image;
        });

    }

    public getMatches(id: number): Promise<ProductMatchDto[]> {
        return tawreedHttpService.post<number, JObject>(endpoints.MATCH_PRODUCT, id, undefined, undefined, 'error').then(res => {
            if (res && res.data) {
                return res.data.map((el: JObject) => ProductMatchMapper.toDto(ProductMatchMapper.fromJson(el)));
            }
            return [];
        });
    }
}
