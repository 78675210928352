import { OrderDto, orderService } from "../../../../../domain";
import { OrderFormListener } from "../../order-form.props";

export const onDeliver = (data: any, listener: OrderFormListener | undefined): Promise<OrderDto | undefined> => {
   
    return orderService
        .deliver(data)
        .then(res => {
            if (res && listener) {
                listener({ type: 'delivered', sender: res });
            }
            return res;
        })
        .catch(() => {
            return undefined;
        });
}
