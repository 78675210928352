import React from "react"
import { PrimeIcons } from "primereact/api"
import { Panel } from "primereact/panel"
import { useHistory, useParams } from "react-router-dom"

import { TawreedAction, TawreedActionName } from "../../../../../common/actions"
import { TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputFile, TawreedInputNumber, TawreedInputSwitch, TawreedInputText, TawreedInputTextarea, TawreedMenuDropdown, TawreedNavLink } from "../../../../../common/components"
import { tawreedTranslationService } from "../../../../../common/translations"
import { Routes } from "../../../../../router"
import { StatesDropdown } from "../../../../states"
import { DeliveryPartner, DeliveryPartnerService } from "../../../domain"
import { DeliveryCostTable } from "./delivery-cost-table"
import { AddressService } from "../../../../states/domain/services"
import { State } from "../../../../states/domain/dtos"

export const DeliveryPartnerForm: React.FC = () => {

    const { id } = useParams<{ id: string | undefined }>();
    const history = useHistory();
    const service: DeliveryPartnerService = React.useMemo(() => new DeliveryPartnerService(), []);
    
    const addressService = React.useMemo(() => new AddressService(), []);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [states, setStates] = React.useState<State[]>([]);
    const [mode, setMode] = React.useState<TawreedFormMode>('Create');
    const [data, setData] = React.useState<DeliveryPartner>({
        active: true,
        requiredOtp:false,
        deliveryCosts:[]
    });
    const [actions, setActions] = React.useState<TawreedAction[]>([]);

    React.useEffect(() => {
        let mounted = true;
        addressService.search()
                .then(res => {
                    if (mounted) {
                        setStates(res);
                    }
                });
        if (id) {
            if (mounted) {
                
        setLoading(true);
                setMode('Edit');
            }
            service.getDetails(Number.parseInt(id))
                .then(res => {
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                })
        } else {
            if (mounted) {
                setMode('Create');
                setData({active: true,
                    requiredOtp:false,
                    deliveryCosts:[]});
            }
        }
        
        return () => {
            mounted = false;
        };
    }, [id, service]);

    const addDeliveryCost = (deliveryCost: { state: string, stateId?: number, deliveryCost?: number, deliverySelling?: number; }) => {
        let deliveryCosts = data.deliveryCosts;
        if (deliveryCosts.filter(x=> x.stateId === deliveryCost.stateId).length==0)
        {
            deliveryCosts?.push({ stateId : deliveryCost.stateId!, deliveryCost: deliveryCost.deliveryCost!,
                 deliverySelling: deliveryCost.deliverySelling!, state: states.filter(x=>x.stateId ==deliveryCost.stateId)[0].stateName })
            setData({ ...data, deliveryCosts: deliveryCosts});
        }
    }

    const deleteDeliveryCost = async (stateId?: number, id?: number | undefined) => {
        try {
            setLoading(true);
            if (id) {
                await service.deleteDeliveryCost(id);
                let deliveryCosts = data.deliveryCosts?.filter(el => el.deliveryCostId !== id);
                setData({ ...data, deliveryCosts: deliveryCosts });
            }
            else {
                let deliveryCosts = data.deliveryCosts?.filter((el) => el.stateId !== stateId);
                setData({ ...data, deliveryCosts: deliveryCosts });
            }

        } catch (err) {
            console.log(err);
        }
        setLoading(false);
    }
    const onCreateOrSave = (data: DeliveryPartner, cb: TawreedFormSubmitCallback<DeliveryPartner>): Promise<void> => {
        setLoading(true);
        return service.createOrUpdate(data)
            .then((res) => {
                if (mode === 'Create') {
                    history.replace(Routes.DeliveryPartnersDetails.replace(':id', res.deliveryPartnerId ? res.deliveryPartnerId.toString() : "0"));
                }
                setLoading(false);
                setData(res);
                cb(res);
            })
            .catch(err => {
                setLoading(false);
                cb(err);
            });
    }

    const onSubmit: TawreedFormSubmitProps<DeliveryPartner> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<DeliveryPartner>(TawreedActionName.DeliveryPartnersSubmit, 'lbl_save', PrimeIcons.SAVE, onCreateOrSave),
    };

    React.useEffect(
        () => {
            const onDelete = () => {
                setLoading(true);
                return service.delete(data!.deliveryPartnerId!).then(res => {
                    setLoading(false);
                    history.replace(Routes.DeliveryPartnersSearch);
                }).catch(err => {
                    setLoading(false);
                    throw err;
                });
            }

            const onDeactivate = () => {
                return service.deactivateAll([data!.partnerId!]).then(res => {
                    setLoading(false);
                    if(res){
                        setData({...data,active:false})
                    }
                }).catch(err => {
                    setLoading(false);
                    throw err;
                });
            }
            const onActivate = () => {
                return service.activateAll([data!.partnerId!]).then(res => {
                    setLoading(false);
                    if(res){
                        setData({...data,active:true})
                    }
                }).catch(err => {
                    setLoading(false);
                    throw err;
                });
            }

            const next = [
            ];
            if (data && data.partnerName) {
                next.push(new TawreedAction(TawreedActionName.DeliveryPartnersDelete, 'statefull', 'lbl_delete', PrimeIcons.TRASH, onDelete, 'p-button-danger', true));
                if(!data.active){
                    next.push(
                        new TawreedAction(TawreedActionName.DeliveryPartnersActivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_activate'), PrimeIcons.CHECK, onActivate, 'p-button-primary', 'confirm')
                        );
                }
                else{
                    next.push(
                    new TawreedAction(TawreedActionName.DeliveryPartnersDeactivate, 'statefull', (e) => tawreedTranslationService.translate('lbl_deactivate'), PrimeIcons.CHECK, onDeactivate, 'p-button-primary', 'confirm')
                    );
                }
            }
            setActions(next);
        },
        [data, service, history]);


    return (
        <TawreedForm title="lbl_delivery_partners"
            dataKey="deliveryPartnerId" data={data}
            submit={onSubmit}
            loading={loading} mode={mode}
            actions={actions} useBack=".." useReset={false} className="grid form-grid">

            <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                <div className="grid">
                    <div className="col-12">
                        <div className="grid">
                            <TawreedFormField name="logoContent" className="field col-12 md:col-3" title="lbl_partners_logo">
                                <TawreedInputFile render="form" name="logoContent" accept="image" />
                            </TawreedFormField>
                        </div>
                    </div>

                    <TawreedFormField name="partnerName" className="field col-12 md:col-6" title="lbl_partners_partner_name">
                        <TawreedInputText name="partnerName" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>
                    <TawreedFormField name="active" title="lbl_payment_partner_active" className="field-checkbox col-12 md:col-6" flip>
                        <TawreedInputSwitch name="active" render="form" />
                    </TawreedFormField>
                    <TawreedFormField name="titleAr" className="field col-12 md:col-6" title="lbl_partners_partner_title_ar">
                        <TawreedInputText name="titleAr" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>
                    <TawreedFormField name="titleEn" className="field col-12 md:col-6" title="lbl_partners_partner_title_en">
                        <TawreedInputText name="titleEn" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>
                    <TawreedFormField name="mawridUniqueId" className="field col-12 md:col-6" title="lbl_partners_partner_mawrid_unique_Id">
                        <TawreedInputText name="mawridUniqueId" render="form" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>
                </div>
            </Panel>

            <Panel header={tawreedTranslationService.translate('lbl_partners_address_information')} className="col-12">
                <div className="grid">
                    <TawreedFormField name="address.stateId" className="field col-12 md:col-12" title="lbl_partners_address_state_id">
                        <StatesDropdown name="address.stateId" rules={{ required: 'msg_field_is_required' }} render="form" />
                    </TawreedFormField>
                    <TawreedFormField name="address.street" className="field col-12" title="lbl_partners_address_street">
                        <TawreedInputText name="address.street" rules={{ required: 'msg_field_is_required' }} render="form" />
                    </TawreedFormField>
                    <TawreedFormField name="address.latitude" className="field col-12 md:col-6" title="lbl_partners_address_latitude">
                        <TawreedInputNumber name="address.latitude" mode="decimal" rules={{ required: 'msg_field_is_required' }} render="form" maxFractionDigits={12} minFractionDigits={0} />
                    </TawreedFormField>
                    <TawreedFormField name="address.longitude" className="field col-12 md:col-6" title="lbl_partners_address_longitude">
                        <TawreedInputNumber name="address.longitude" mode="decimal" rules={{ required: 'msg_field_is_required' }} render="form" maxFractionDigits={12} minFractionDigits={0} />
                    </TawreedFormField>
                </div>
            </Panel>

            <Panel header={tawreedTranslationService.translate('lbl_delivery_partners_costs_information')} className="col-12">
                <div className="grid">

                    <TawreedFormField className="field col-6 md:col-6" name="codCost" title="lbl_delivery_partners_codCost">
                        <TawreedInputNumber render="form" name="codCost" mode="decimal" rules={{ required: 'msg_field_is_required' }} />
                    </TawreedFormField>

                    <TawreedFormField className="field col-6 md:col-6" name="codSelling" title="lbl_delivery_partners_codSelling">
                        <TawreedInputNumber render="form" name="codSelling" mode="decimal" rules={{ required: 'msg_field_is_required', max: 100, min: 0 }} />
                    </TawreedFormField>
                    <TawreedFormField name="requiredOtp" title="lbl_delivery_partners_requiredOtp" className="field-checkbox col-12 md:col-6" flip>
                        <TawreedInputSwitch name="requiredOtp" render="form" />
                    </TawreedFormField>
                </div>
                <Panel header={tawreedTranslationService.translate('lbl_delivery_costs')} >
                {
                   <DeliveryCostTable
                   addDeliveryCost = {addDeliveryCost}
                   deleteDeliveryCost ={deleteDeliveryCost}
                   data={data.deliveryCosts}
                   loading={loading}
               ></DeliveryCostTable>
                }
            </Panel >
            </Panel>
        </TawreedForm>
    )
}
