import {DataTableFilterMatchModeType, DataTableSortParams} from "primereact/datatable";
import {DateUtils} from "../../helpers/date";
import {TawreedRequest, TawreedResponse} from "../../http";
import {TawreedDataTableFilterMeta} from "../components/table";

export interface Result<T> {
    content: Array<T>;
    sort: {
        sorted: boolean;
        unsorted: boolean;
        empty: boolean;
    };
    pageable: {
        sort: {
            sorted: boolean;
            unsorted: boolean;
            empty: boolean;
        };
        offset: number;
        pageSize: number;
        pageNumber: number;
        paged: boolean;
        unpaged: boolean;
    };
    totalPages: number;
    totalElements: number;
    first: boolean;
    last: boolean;
    empty: boolean
    size: number;
    number: number;
    numberOfElements: number;
    request: PaginatedRequestParams;
}

export interface PaginatedRequestParams {
    size: number,
    page: number,
    sort?: DataTableSortParams;
    keyword?: string;
    filters?: TawreedDataTableFilterMeta;
}

export interface PaginatedRequestCriteria {
    fieldName: string;
    operator: string;
    value: string;
}

export interface PaginatedRequest extends TawreedRequest<{}> {
    data: {
        keyword: string;
        criteria: PaginatedRequestCriteria[];
    }
}

export interface PaginatedResponse<T> extends TawreedResponse<Result<T>> {

}

export const PAGE_SIZE_OPTIONS = [10, 25, 50, 100];

const DEFAULT_PAGE_SIZE = 10;

export function buildPaginatedRequest(from: Partial<PaginatedRequestParams>): PaginatedRequestParams {
    return {
        size: from.size || DEFAULT_PAGE_SIZE,
        page: from.page || 0,
        sort: from.sort && from.sort.sortField ? {sortField: from.sort.sortField, sortOrder: from.sort.sortOrder || 0, multiSortMeta: from.sort.multiSortMeta} : undefined,
        filters: from.filters,
        keyword: from.keyword,
    }
}

export function buildSortParamsToString(sort: DataTableSortParams): string | undefined {
    if (!sort || !sort.sortField || !sort.sortOrder) {
        return undefined;
    }
    let order: 'asc' | 'desc' | '' = '';
    if (sort.sortOrder === 1) {
        order = 'asc';
    }
    if (sort.sortOrder === -1) {
        order = 'desc';
    }
    return sort.sortField + ',' + order;
}

export function buildFilterMetaToObject(keyword?: string, params?: TawreedDataTableFilterMeta): { [key: string]: any } {
    // const keyword = params && params['keyword'] ? (params['keyword'] as any).value : undefined;
    const criteria: PaginatedRequestCriteria[] = [];

    function indexToOperation(index: number, length: number): 'start' | 'end' | 'none' {
        if (index === 0) {
            return 'start';
        } else if (index === length - 1) {
            return 'end';
        } else return 'none';
    }

    function valueToString(e: any, operation: 'start' | 'end' | 'none', matchMode: DataTableFilterMatchModeType): string {
        if (e instanceof Date) {
            if (matchMode === "dateIs" || matchMode === "dateIsNot") {
                return `${DateUtils.toISO(e, 'datetime', 'start')},${DateUtils.toISO(e, 'datetime', 'end')}`;
            } else if (matchMode === "dateAfter") {
                return DateUtils.toISO(e, 'datetime', 'start');
            }
            return DateUtils.toISO(e, 'datetime', 'end');
        }
        return e;
    }

    function matchModeToString(matchMode: DataTableFilterMatchModeType): string {
        if (matchMode === "dateIs") {
            return "between";
        } else if (matchMode === "dateIsNot") {
            return "notBetween";
        } else {
            return matchMode;
        }
    }


    if (params) {
        Object.keys(params).forEach(key => {
            if (key) {
                const element: any = params[key];
                if (element.value !== undefined && element.value != null) {
                    criteria.push({
                        fieldName: key,
                        operator: matchModeToString(element.matchMode),
                        value: Array.isArray(element.value) ? Array.from(element.value).map((v, index) => valueToString(v, indexToOperation(index, element.value.length), element.matchMode)).join(',') : valueToString(element.value, 'start', element.matchMode),
                    });
                }
                if (element.constraints && Array.isArray(element.constraints)) {
                    element.constraints.forEach((constraint: any) => {
                        if (constraint.value !== undefined && constraint.value != null) {
                            criteria.push({
                                fieldName: key,
                                operator: matchModeToString(constraint.matchMode),
                                value: Array.isArray(constraint.value) ? Array.from(constraint.value).map((v, index) => valueToString(v, indexToOperation(index, constraint.value.length), constraint.matchMode)).join(',') : valueToString(constraint.value, 'start', constraint.matchMode),
                            });
                        }
                    });
                }
            }
        });
    }
    return {keyword, criteria};
}

export function isPaginatedRequestParams(obj: any): obj is PaginatedRequestParams {
    return obj !== undefined && obj.size && obj.page;
}