import { buildPaginatedRequest, isPaginatedRequestParams, PaginatedRequestParams, PaginatedResponse } from "../../../../common/pagination";
import { ZonesRepository } from "../../data/repositories";
import { Zone } from "../dtos";

export class ZonesService {

    private repo = new ZonesRepository();

    public search(): Promise<PaginatedResponse<Zone>>;
    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<Zone>>;
    public search(arg: PaginatedRequestParams | void): Promise<PaginatedResponse<Zone>> {
        if (isPaginatedRequestParams(arg)) {
            return this.repo.search(arg);
        } else {
            const req = buildPaginatedRequest({
                sort: { sortOrder: 1, sortField: 'zoneName', multiSortMeta: undefined },
                filters: {
                },
            });
            return this.repo.search(req);
        }
    }

    /**
     * Create or update partner instance
     * @param type
     * @param form
     */
    public createOrUpdate(form: Zone): Promise<Zone> {
        return this.repo.createOrUpdate(form);
    }

    /**
     * Delete partner by id
     * @param id
     */
    public async delete(id: number): Promise<boolean> {
        return this.repo.delete(id);
    }


    /**
     * Delete all partners by ids
     * @param ids
     */
    public async deleteAll(ids: number[]): Promise<boolean> {
        return this.repo.deleteAll(ids);
    }

    /**
     * Get partner details by id
     * @param id
     */
    public async details(id: number): Promise<Zone> {
        return this.repo.details(id);
    }
}