import React from 'react';
import { Button } from 'primereact/button';
import { PrimeIcons } from 'primereact/api';
import { classNames } from 'primereact/utils';

import { TawreedFile } from '../../../../domain';
import { ImageViewer, VideoViewer } from '../../viewers';

import './item.template.scss';

export type InputFileItemProps = {
    /**
     *
     */
    value?: TawreedFile;

    /**
     *
     */
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;

    /**
     *
     */
    onRemove?: () => void;

    accept?: string;

    disabled?: boolean;
}

export const InputFileItem: React.FC<InputFileItemProps> = (props) => {

    const input = React.useRef<HTMLInputElement>(null);
    const [viewer, setViewer] = React.useState<'image' | 'video' | 'other'>();

    React.useEffect(() => {

        const value = props.value;
        if (value && value.value) {
            if (value.value instanceof File) {
                if (value.value.type.startsWith('image')) {
                    setViewer('image');
                } else if (value.value.type.startsWith('video')) {
                    setViewer('video');
                } else {
                    setViewer('other');
                }
            } else {
                setViewer('image');
            }
        } else {
            setViewer(undefined);
        }

    }, [props.value])


    const getName = (e: TawreedFile) => {
        if (e.fileName) {
            return e.fileName;
        } else if (e.value instanceof File) {
            return e.value.name;
        } else {
            return '';
        }
    }

    return (
        <React.Fragment>

            <div className={classNames('tawreed-input-file-item', { 'empty': !viewer })}>
                <input ref={input} type="file" hidden onChange={props.onChange} accept={props.accept} />
                <div className="flex align-items-center justify-content-center view">
                    {
                        props.value && viewer === 'image' && <ImageViewer value={props.value} className="image" />
                    }
                    {
                        props.value && viewer === 'video' && <VideoViewer value={props.value} className="video" />
                    }
                    {
                        props.value && viewer === 'other' && <p className="other">{getName(props.value)}</p>
                    }
                </div>
                {!props.disabled && <div className="flex align-items-center justify-content-center controls">
                    {
                        props.onRemove &&
                        <Button type="button"
                            icon={PrimeIcons.TRASH}
                            onClick={() => {
                                if (props.onRemove) {
                                    props.onRemove();
                                }
                            }}
                            className="p-button-rounded p-button-text" />
                    }
                    <Button type="button"
                        icon={PrimeIcons.PLUS}
                        onClick={() => {
                            if (input.current) {
                                input.current.click();
                            }
                        }}
                        className="p-button-rounded p-button-text" />
                </div>}
            </div>
        </React.Fragment>
    )
}
