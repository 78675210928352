import React from 'react'
import { PrimeIcons } from 'primereact/api';
import { useHistory } from 'react-router-dom';

import { TawreedActionName, TawreedAction } from '../../../../../common/actions';
import { TawreedTable, TawreedTableProps } from '../../../../../common/components/table';
import { Routes } from '../../../../../router';
import { OrderDto, orderService as service } from '../../domain';
import { TawreedTableFilters } from '../../../../../common/components/table/filters';
import { GlobalContext } from '../../../../../context';
import { TawreedTableFormatters } from "../../../../../common/components/table/formatters";
import { OrderStatus } from "../../data";
import { PartnerType } from "../../../../partners/data";
import { CssUtils } from "../../../../../helpers/css";
import { DataTableSelectionModeType } from 'primereact/datatable';
import { TawreedTableDataSourceTrigger } from '../../../../../common/components/table/source';

export type OrdersTableProps = {
    /**
     * use returned orders context?
     */
    returned?: boolean;

    /**
     * status id
     */
    statusId?: OrderStatus;

    /**
     * partner type
     */
    partnerType?: PartnerType;
    partnerId?: number;

    /**
     * carrierId
     */
    carrierId?: number;

    /**
     * actions
     */
    actions?: Array<TawreedAction>;

    name: string;

    selectionMode?: DataTableSelectionModeType;
    selectionSelectAllOnLoad?: boolean;
    triggerMode?: TawreedTableDataSourceTrigger;

    pagination?: boolean;
}

export const OrdersTable: React.FC<OrdersTableProps> = ({ pagination, triggerMode, name, actions, returned, statusId, partnerId, partnerType, carrierId, selectionMode, selectionSelectAllOnLoad }) => {

    const { constants: { constants } } = React.useContext(GlobalContext);

    const history = useHistory();

    const onForm = (sender?: OrderDto): Promise<void> => {
        return new Promise((resolve) => {
            const url = returned ? Routes.SalesOrdersReturnedDetails : Routes.SalesOrdersDetails;
            if (url && sender && sender.orderId) {
                history.push(url.replace(':id', sender.orderId.toString()))
            }
            resolve();
        });
    };

    const tawreed: TawreedTableProps = {
        header: true,
        pagination: pagination ?? true,
        dataKey: 'customer',
        name: name,
        title: returned ? 'lbl_sales_returned_orders' : 'lbl_sales_orders',
        ds: {
            trigger: triggerMode,
            mode: 'lazy',
            onSearch: (req) => service.search(req, returned),
        },
        columns: returned ?
            [
                {
                    dataType: 'text',
                    field: "statusId",
                    header: "lbl_sales_orders_status",
                    sortable: true,
                    filter: true,
                    ...TawreedTableFilters.dropdown.dropdown("statusId", constants?.orderStatuses ?? [], 'value', 'label', 'in'),
                    style: CssUtils.width('10%'),
                },
                {
                    dataType: 'numeric',
                    field: "orderId",
                    header: "lbl_sales_orders_return_id",
                    sortable: true,
                    filter: true,
                    style: CssUtils.width('15%'),
                },
                {
                    dataType: 'numeric',
                    field: "salesOrderId",
                    header: "lbl_sales_orders_order_id",
                    sortable: true,
                    filter: true,
                    style: CssUtils.width('15%'),
                },
                {
                    field: "orderDate",
                    header: "lbl_sales_orders_date",
                    sortable: true,
                    filter: true,
                    ...TawreedTableFilters.date.range("orderDate", 'datetime'),
                    style: CssUtils.width('15%'),
                },
                {
                    dataType: 'text',
                    field: "customer.partnerName",
                    header: "lbl_sales_orders_customer",
                    sortable: true,
                    sortField: 'customer',
                    filter: true,
                    filterField: 'customer',
                    style: CssUtils.width('20%'),
                },
                {
                    dataType: 'text',
                    field: "store.partnerName",
                    header: "lbl_sales_orders_store",
                    sortable: true,
                    sortField: 'store',
                    filter: true,
                    filterField: 'store',
                    style: CssUtils.width('20%'),
                },
                {
                    field: "orderTotal",
                    header: "lbl_sales_orders_order_total",
                    sortable: true,
                    filter: true,
                    ...TawreedTableFormatters.decimal('orderTotal', 0, CssUtils.width('15%')),
                    className: CssUtils.className.field.amount,
                },
            ]
            :
            [
                {
                    dataType: 'text',
                    field: "statusId",
                    header: "lbl_sales_orders_status",
                    sortable: true,
                    filter: true,
                    ...TawreedTableFilters.dropdown.dropdown("statusId", constants?.orderStatuses ?? [], 'value', 'label', 'in'),
                    style: CssUtils.width('10%'),
                },
                {
                    dataType: 'numeric',
                    field: "orderId",
                    header: "lbl_sales_orders_order_id",
                    sortable: true,
                    filter: true,
                    style: CssUtils.width('15%', CssUtils.color('var(--danger-color)')),
                },
                {
                    field: "orderDate",
                    header: "lbl_sales_orders_date",
                    sortable: true,
                    filter: true,
                    ...TawreedTableFilters.date.range("orderDate", 'datetime'),
                    style: CssUtils.width('15%'),
                },
                {
                    dataType: 'text',
                    field: "customer.partnerName",
                    header: "lbl_sales_orders_customer",
                    sortable: true,
                    sortField: 'customer',
                    filter: true,
                    filterField: 'customer',
                    style: CssUtils.width('25%', CssUtils.color('var(--success-color)')),
                },
                {
                    dataType: 'text',
                    field: "store.partnerName",
                    header: "lbl_sales_orders_store",
                    sortable: true,
                    sortField: 'store',
                    filter: true,
                    filterField: 'store',
                    style: CssUtils.width('25%'),
                },
                {                    
                    field: "orderTotal",
                    header: "lbl_sales_orders_order_total",
                    sortable: true,
                    filter: true,
                    ...TawreedTableFormatters.decimal('orderTotal', 0, CssUtils.width('10%')),
                    className: CssUtils.className.field.amount,
                },
            ],
        toolbar: {
            actions: actions ?? [],
        },
        row: {
            onClick: new TawreedAction(TawreedActionName.OrdersDetails, 'statefull', 'lbl_info', PrimeIcons.INFO, onForm),
        },
        filter: {
            filterDisplay: 'menu',
            initial: {
                carrierId: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: carrierId,
                            matchMode: 'equals',
                        }
                    ]
                },
                customerId: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: partnerType && partnerType === PartnerType.Customer ? partnerId : undefined,
                            matchMode: 'equals',
                        }
                    ]
                },
                storeId: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: partnerType && partnerType === PartnerType.Store ? partnerId : undefined,
                            matchMode: 'equals',
                        }
                    ]
                },
                statusId: {
                    operator: 'AND',
                    constraints: [
                        statusId ? { value: [statusId], matchMode: 'in' } : { value: null, matchMode: 'in' },
                    ]
                },
                orderId: {
                    operator: 'AND',
                    constraints: [
                        { value: null, matchMode: 'equals' },
                    ]
                },
                orderDate: {
                    operator: 'AND',
                    constraints: [
                        { value: null, matchMode: 'dateIs' }
                    ]
                },
                customer: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        }
                    ]
                },
                store: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'contains',
                        }
                    ]
                },
                orderTotal: {
                    operator: 'AND',
                    constraints: [
                        {
                            value: null,
                            matchMode: 'equals',
                        },
                    ]
                },
            },
        },
        sort: {
            sortMode: 'single',
            initial: {
                sortField: 'orderId',
                sortOrder: -1,
                multiSortMeta: undefined,
            },
        },
        selection: {
            mode: selectionMode ?? 'single',
            selectAllOnLoad: selectionSelectAllOnLoad ?? undefined,
        },
    }

    return (<TawreedTable  {...tawreed} />);
}
