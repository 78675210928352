import { JObject } from "../../../../common/data/models"
import { Partner, PartnerType, PartnerStatus } from "../models"
import { PartnerDto } from "../../domain";
import { GoogleMapsUtils } from "../../../../helpers/google-maps";
import { StoreZoneMapper } from "./store-zone";
import { StoreProductCategoryMapper } from "./store-product-category";
import { PartnerFileMapper } from "./partner-file";
import { DateUtils } from "../../../../helpers/date";
import { TawreedFileMapper } from "../../../upload/data";

export const PartnerMapper = {
    /**
     * Create cart model from json object
     * @param json
     * @returns {Partner}
     */
    fromJson: (json: JObject): Partner => {
        return {
            partnerId: json.partnerId,
            partnerName: json.partnerName,
            partnerType: json.partnerType,
            active: json.active ?? false,
            licenseContentId: json?.licenseContentId,
            licenseNo: json?.licenseNo,
            tradeRegisterContentId: json?.tradeRegisterContentId,
            logoContent: json?.logoContent,
            address: {
                addressId: json.address?.addressId,
                state: json.address?.state ?? json.state,
                stateId: json.address?.stateId ?? json.stateId,
                zoneId: json.address?.zoneId ?? json.zoneId,
                latitude: json.address?.latitude ?? json.latitude,
                longitude: json.address?.longitude ?? json.longitude,
                street: json.address?.street ?? json.street,
                fullAddress: json.address?.fullAddress,
                city: json.address?.city,
                cityId: json.address?.cityId
            },
            startDate: json.startDate,
            state: json.state,
            statusId: json.statusId,
            verified: json.verified,
            commissionPercent: json.commissionPercent,
            contact: {
                contactId: json.contact?.contactId,
                contactName: json.contact?.contactName,
                email: json.contact?.email,
                // mobileNo: json.contact?.mobileNo ? (`+${json.contact.mobileNo}`) : undefined,
                mobileNo: json.contact?.mobileNo ? (`${json.contact.mobileNo}`) : undefined,
            },
            orderMin: json.partnerType === PartnerType.Store ? json.orderMin : undefined,
            priority: json.partnerType === PartnerType.Store ? json.priority : undefined,
            zoneId: json.zoneId,
            businessType: json.businessType,
            nationalId: json?.nationalId,
            walletId: json?.walletId,
            walletStatus: json?.walletStatus,
            canShipping: json?.canShipping,
            establishmentNationalId: json?.establishmentNationalId,
            licenceExpireDate: json?.licenceExpireDate ? DateUtils.fromISO(new Date(json?.licenceExpireDate).toISOString()) : undefined,
            zoneList: json?.zoneList?.map((el: JObject) => StoreZoneMapper.fromJson(el)) ?? [],
            storeProductCategoryList: json?.storeProductCategoryList?.map((el: JObject) => StoreProductCategoryMapper.fromJson(el)) ?? [],
            files: json?.files?.map((el: JObject) => PartnerFileMapper.fromJson(el)) ?? [],
        }
    },

    /**
     *
     */
    toJson: (partner: Partner): JObject => {
        return { ...partner, active: partner.active ?? false, logoContent: partner.logoContent ?? undefined }
    },

    /**
     * Create model from json object
     * @param dto
     * @returns {Partner}
     */
    fromDto: (dto: PartnerDto): Partner => {
        return {
            partnerId: dto.partnerId,
            partnerType: dto.partnerType,
            active: dto.active,
            partnerName: dto.partnerName,
            commissionPercent: dto.commissionPercent,
            licenseContentId: dto.licenseContentId,
            licenseNo: dto.licenseNo,
            tradeRegisterContentId: dto.tradeRegisterContentId,
            logoContent: dto.logoContent && dto.logoContent.value ? TawreedFileMapper.toString(dto.logoContent) : undefined,
            address: {
                zoneId: dto.address?.zoneId,
                stateId: dto.address?.stateId,
                state: dto.address?.state,
                street: dto.address?.street,
                latitude: dto.address?.latitude,
                longitude: dto.address?.longitude,
                fullAddress: dto.address?.fullAddress,
                city: dto.address?.city,
                cityId: dto.address?.cityId
            },
            contact: {
                contactId: dto.contact?.contactId,
                contactName: dto.contact?.contactName,
                email: dto.contact?.email,
                mobileNo: dto.contact?.mobileNo,
            },
            orderMin: dto.orderMin,
            priority: dto.priority,
            zoneId: dto.zoneId,
            businessType: dto.businessType,
            nationalId: dto.nationalId,
            establishmentNationalId: dto.establishmentNationalId,
            licenceExpireDate: dto.licenceExpireDate,
            zoneList: dto.zoneList?.map((el: JObject) => StoreZoneMapper.fromDto(el)) ?? [],
            storeProductCategoryList: dto.storeProductCategoryList?.map((el: JObject) => StoreProductCategoryMapper.fromDto(el)) ?? [],
            files: dto.files ?? [],
            walletId: dto.walletId,
            walletStatus: dto.walletStatus,
            canShipping: dto.canShipping,
        }
    },

    /**
     *
     */
    toDto: (model: Partner): PartnerDto => {
        return {
            partnerId: model.partnerId,
            partnerType: model.partnerType,
            active: model.active,
            partnerName: model.partnerName,
            status: model.statusId === 2 ? PartnerStatus.UnVerified : PartnerStatus.Verified,
            commissionPercent: model.commissionPercent,
            licenseContentId: model.licenseContentId,
            licenseNo: model.licenseNo,
            tradeRegisterContentId: model.tradeRegisterContentId,
            logoContent: TawreedFileMapper.fromJson({
                fileName: `PARTNERS_FILES_${model.partnerId}`,
                value: model.logoContent,
            }),
            address: {
                zoneId: model.address?.zoneId,
                state: model.address?.state,
                stateId: model.address?.stateId,
                street: model.address?.street,
                latitude: model.address?.latitude,
                longitude: model.address?.longitude,
                address: model.address?.fullAddress,
                location: (model.address?.latitude && model.address?.longitude) ? GoogleMapsUtils.getSearch(model.address?.latitude, model.address?.longitude) : undefined,
                fullAddress: model.address?.fullAddress,
                city: model.address?.city,
                cityId: model.address?.cityId
            },
            contact: {
                ...model.contact,
                contactId: model.contact?.contactId,
                contactName: model.contact?.contactName,
                email: model.contact?.email,
                mobileNo: model.contact?.mobileNo,
            },
            startDate: model.startDate,
            orderMin: model.orderMin,
            priority: model.priority,
            zoneId: model.zoneId,
            businessType: model.businessType,
            nationalId: model.nationalId,
            establishmentNationalId: model.establishmentNationalId,
            licenceExpireDate: model.licenceExpireDate,
            zoneList: model.zoneList?.map((el: JObject) => StoreZoneMapper.toDto(el)) ?? [],
            storeProductCategoryList: model.storeProductCategoryList?.map((el: JObject) => StoreProductCategoryMapper.toDto(el)) ?? [],
            files: model.files ?? [],
            walletId: model.walletId,
            walletStatus: model.walletStatus,
            canShipping: model.canShipping,
        };
    },
}
