import { JObject } from "../../../../../common/data/models";
import { ReadyToDeliveryDto, ShipmentPackage } from "../../domain/dtos/ready-to-delivery";

export const ReadyToDeliveryMapper = {

    toJson: (dto: ReadyToDeliveryDto): JObject => {
        return {
            salesOrderId:dto.orderId,
            shipmentPackageDtoList: dto.shipmentPackageDtoList.map((el:ShipmentPackage) => 
            
            {
                return {
                    remark: el.remark,
                    requireRefrigerator: el.requireRefrigerator
                }
            }) 
        }
    }
}