import { InvoiceReportItem, InvoiceReportParams, ReportDto, ReportResultDto } from "../../domain";
import { JObject } from "../../../../common/data/models";
import { tawreedHttpService } from "../../../../http";
import { endpoints } from "../../../../constants/endpoints";
import { ReportMapper } from "../mappers";
import { PaginatedRequestParams } from "../../../../common/pagination";
import { TawreedTableFilters } from "../../../../common/components/table/filters";
import { CssUtils } from "../../../../helpers/css";
import { ReportRepository } from "./report.repository";
import { InvoiceReportParamsMapper } from "../mappers/invoices-params";
import { InvoiceReportMapper } from "../mappers/invoices-report";
import { TawreedConstants } from "../../../constants";
import { TawreedTableFormatters } from "../../../../common/components/table/formatters";
import { TawreedAction, TawreedActionName } from "../../../../common/actions";
import { PrimeIcons } from "primereact/api";

export class InvoicesReportRepository extends ReportRepository<InvoiceReportParams, InvoiceReportItem> {

    /**
     *
     * @param params
     */
    public generate(params: InvoiceReportParams, constants: TawreedConstants | undefined, returned?: boolean): Promise<ReportDto<InvoiceReportItem>> {
        if (returned) {
            return Promise.reject('UN_SUPPORTED_RENDER_MODE');
        }
        const dataKey = 'invoiceId';
        const columns = [
            { field: "invoiceId", header: 'lbl_reports_invoices_invoiceid', style: CssUtils.width('10%') },
            { field: "invoiceDate", header: 'lbl_reports_invoices_invoicedate', ...TawreedTableFilters.date.range('invoiceDate', 'date'), style: CssUtils.width('10%') },
            { field: "description", header: 'lbl_reports_invoices_description', style: CssUtils.width('50%') },
            { field: "amount", header: 'lbl_reports_invoices_amount', ...TawreedTableFormatters.decimal('amount', 0, CssUtils.width('10%')) },
            { field: "tax", header: 'lbl_reports_invoices_tax', ...TawreedTableFormatters.decimal('tax', 0, CssUtils.width('10%')) },
            { field: "total", header: 'lbl_reports_invoices_total', ...TawreedTableFormatters.decimal('total', 0, CssUtils.width('10%')) },
        ];
        const actions = (rowData: InvoiceReportItem) => {
            const res = new Array<TawreedAction>();
            if (rowData.showPrintInvoice) {
                const onExportAs = (url?: string) => {
                    return new Promise<void>((resolve, reject) => {
                        try {
                            this.export(url!);
                            resolve();
                        } catch (error) {
                            reject(error);
                        }
                    });
                }
                res.push(new TawreedAction(TawreedActionName.ReportsExport, 'statefull', 'lbl_reports_export_as_pdf', PrimeIcons.FILE_PDF, () => onExportAs(rowData.invoiceUrls.pdfReportURI)));
                res.push(new TawreedAction(TawreedActionName.ReportsExport, 'statefull', 'lbl_reports_export_as_excel', PrimeIcons.FILE_EXCEL, () => onExportAs(rowData.invoiceUrls.xlsReportURI)));
            }
            return res;
        };
        console.log(params);
        const filter = InvoiceReportParamsMapper.toFilter(params);
        console.log(filter);
        const onSearch = (req: PaginatedRequestParams) => this.search(req);
        return Promise.resolve(ReportMapper.toDto({ ...params, dataKey, columns, filter, onSearch, actions }));
    }

    public search(request: PaginatedRequestParams): Promise<ReportResultDto<InvoiceReportItem>> {
        const params = {
            sort: undefined,
            page: request.page,
            size: request.size,
        };
        const data = this.fromRequestParamsToJObject(request);

        return tawreedHttpService.post<JObject, JObject>(endpoints.REPORTS_INVOICES_FETCH, data, params, undefined, 'error')
            .then((res) => {
                if (!res || !res.data) {
                    return Promise.reject(null);
                }
                return InvoiceReportMapper.fromJson(res.data);
            });
    }
}
