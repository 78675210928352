import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import React, { FC, useState } from 'react'
import { tawreedTranslationService } from '../../../../../../common/translations';
import { TawreedFormField, TawreedInputNumber, TawreedInputSwitch, TawreedInputText, TawreedInputTextarea } from '../../../../../../common';
import { InputSwitchChangeParams } from 'primereact/inputswitch';
import * as actions from "./actions";
import { OrderFormListener } from './order-form.props';
import { ShipmentPackage } from '../../../domain/dtos/ready-to-delivery';
import { Panel } from 'primereact/panel';

export interface OrderPackagesProps {
    visible: boolean;
    orderId: number;

    hide: () => void;

    //accept: () => void;
    listener?: OrderFormListener;
}

export const PackagesDialog: FC<OrderPackagesProps> = ({ visible, hide, orderId, listener }) => {

    const [shipmentPackages, setShipmentPackages] = useState<ShipmentPackage[]>([
        {remark: "",requireRefrigerator:false}
    ]);
    const [numberOfPackages, setNnumberOfPackages] = useState<number>(1);

    const [disabled, setDisabled] = useState<boolean>(false);

    const onReady = () => {
        if(shipmentPackages.length>0){
            setDisabled(true);
        return actions.onReady(orderId, shipmentPackages, listener)
            .then(res => {
                if (res) {
                    hide();
                }
                
                setDisabled(false);
            });
        }
        
        setDisabled(false);
    }

    const handleFormChange = (index: number, event: any) => {
        let data = [...shipmentPackages];
        if (event.target.name == "remark")
            data[index].remark = event.target.value;
        else
            data[index].requireRefrigerator = event.target.value;

        setShipmentPackages(data);
    }
    const handleCountChange = (count: number) => {
        setNnumberOfPackages(count);
        var newFields = [];
        for (var i = 0; i < count; i++) {
            let newfield = { remark: '', requireRefrigerator: false }
            newFields.push(newfield);
        }

        setShipmentPackages(newFields)
    }
    const ShipmentDialogFooter = () => {
        return (
            <div>
                <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={hide} className="p-button-text" />
                <Button onClick={() => onReady()} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check" disabled={disabled} />
            </div>
        );
    }

    return (

        <Dialog style={{ maxWidth: '512px', minWidth: '55%' }}
         header={<span style={{ fontSize: '13px', fontWeight: 'bold' }}>{tawreedTranslationService.translate('lbl_readyToDeliver')}</span>}
            visible={visible} footer={<ShipmentDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>


            <React.Fragment>
                <div style={{ width: '100%', justifyContent: 'center' }}>
                    <TawreedFormField name="title" title="lbl_readyToDeliver_number_of_package" className="field col-12 md:col-6">
                        <TawreedInputNumber min={1} value={numberOfPackages} onChange={(e: any) => handleCountChange(e.value)} name="title" render="standalone" />
                    </TawreedFormField>
                    <Panel header={tawreedTranslationService.translate('lbl_general_information')} className="col-12">
                    <div className="grid">
                    {shipmentPackages.map((input, index) => {
                        return (
                            <div key={index} className="col-12 grid">
                                 <TawreedFormField name="requireRefregerator" title="lbl_readyToDeliver_requireRefregerator" className="field col-2 md:col-2">
                                    <TawreedInputSwitch checked={input.requireRefrigerator} onChange={(e) => handleFormChange(index, e)} name="requireRefregerator" render="standalone" />
                                </TawreedFormField>
                                <TawreedFormField name="remark" title="lbl_readyToDeliver_remark" className="field col-10 md:col-10">
                                    <TawreedInputTextarea onChange={(e) => handleFormChange(index, e)} value={input.remark} name="remark" render="standalone" />
                                </TawreedFormField>
                            </div>
                        )
                    })}
                    </div>
                    </Panel>
                </div>


            </React.Fragment>

        </Dialog >
    )
}
