import React from 'react'
import { PartnerType } from '../../../../partners/data';
import { OrderStatus } from '../../data';
import { PartnersOrdersTable } from '../components/partners-orders-table';
import {PartnerOrdersView} from "../components/partner-orders-view";

/**
 *
 * @returns
 */
export const StoresPlacedOrdersPage: React.FC = () => <PartnersOrdersTable statusId={OrderStatus.Placed} partnerType={PartnerType.Store} />;

export const StoresReadyOrderPage:React.FC = () => <PartnersOrdersTable statusId={OrderStatus.READY_FOR_DELIVERY} partnerType={PartnerType.Store} />;
export const StoreReadyOrderPage:React.FC = () => <PartnerOrdersView statusId={OrderStatus.READY_FOR_DELIVERY} partnerType={PartnerType.Store} />;
/**
 *
 *
 * @returns
 */
export const StorePlacedOrdersPage: React.FC = () => <PartnerOrdersView statusId={OrderStatus.Placed} partnerType={PartnerType.Store} />;

/**
 *
 * @returns
 */
export const StoresProcessingOrdersPage: React.FC = () => <PartnersOrdersTable statusId={OrderStatus.Processing} partnerType={PartnerType.Store} />;

/**
 *
 * @returns
 */
export const StoreProcessingOrdersPage: React.FC = () => <PartnerOrdersView statusId={OrderStatus.Processing} partnerType={PartnerType.Store} />;

/**
 *
 * @returns
 */
export const CustomersShippedOrdersPage: React.FC = () => <PartnersOrdersTable statusId={OrderStatus.Shipped} partnerType={PartnerType.Customer} />;

/**
 *
 * @returns
 */
export const CustomerShippedOrdersPage: React.FC = () => <PartnerOrdersView statusId={OrderStatus.Shipped} partnerType={PartnerType.Customer} />;
