import React from "react";
import { ProductDto, ProductsService } from "../../domain";
import { TawreedAutoComplete, TawreedControlRenderModeWithoutNested, TawreedControlRules } from "../../../../../common/components/form";
import { AutoCompleteSelectParams } from "primereact/autocomplete";
import { AuthUtils } from "../../../../auth/domain";

export type ProductAutoCompleteComponentProps = {
    //
    service?: ProductsService;

    // autocomplete
    autoCompleteName: string;
    autoCompleteRender: TawreedControlRenderModeWithoutNested;
    autoCompleteDisabled?: boolean;
    autoCompleteRules?: TawreedControlRules;
    autoCompleteOnSelect?(e: AutoCompleteSelectParams): void;
    autoCompleteDefaultQuery?: any;
    storeId?: number;
    value?: any;
};

export const ProductAutoCompleteComponent: React.FC<ProductAutoCompleteComponentProps> = (props) => {
    const service: ProductsService = props.service ?? new ProductsService();
    console.log(props.storeId);
    const search = (q: string) => {
        q += `§${props.storeId?.toString()}`;
        return service.search('similar', q).then(res => res.data?.content ?? []);
    };

    const itemTemplate = (rowData: ProductDto) => {

        return (
            <div className="">
                {/* <span className="">{AuthUtils.current().language === 'en' ? rowData.productNameEn : rowData.productName}</span> */}
                <span className="">{rowData.displayName}</span>

            </div>
        )
    }

    return (
        <TawreedAutoComplete name={props.autoCompleteName}
            render={props.autoCompleteRender}
            disabled={props.autoCompleteDisabled}
            rules={props.autoCompleteRules}
            itemTemplate={itemTemplate}
            scrollHeight="18em"
            field={AuthUtils.current().language === 'en' ? "displayName" : "displayName"}
            defaultQuery={props.autoCompleteDefaultQuery}
            value={props.value}
            onSelect={props.autoCompleteOnSelect}
            onSearch={search} />
    );
}
