import { Dialog } from "primereact/dialog"
import { tawreedTranslationService } from "../../../../../../common/translations";
import React, { FC, useState } from "react";
import { TawreedFormField, TawreedInputFile } from "../../../../../../common";
import { TawreedFile } from "../../../../../upload";
import { Button } from "primereact/button";
import OtpInput from "react-otp-input";
import { OrderList } from "primereact/orderlist";
import { PrimeIcons } from "primereact/api";

export interface OrderDeliverDocumentUploadDialogProps {
    visible: boolean;

    hide: () => void;
    requireOtp: boolean;
    payThroughUs: boolean;
    accept: (data: { files: Array<string> | undefined, code: string | undefined }) => void;
}

export const OrderDeliverDocumentUploadDialog: FC<OrderDeliverDocumentUploadDialogProps> = ({ visible, hide, requireOtp, payThroughUs, accept }) => {

    const [dialogState, setDialogState] = useState<{
        files: Array<File> | undefined,
        code: string | undefined
    }>({
        files: [],
        code: undefined
    });

    const returnFile = async () => {
        if (dialogState.files) {

            let documents: Array<string> = [];
            for (let i = 0; i < (dialogState.files ?? []).length; i++) {
                const file = dialogState.files[i];
                let document = "";
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    document = reader.result as string;
                    let documentParts = document.split(',');
                    if (documentParts.length > 1) {
                        document = documentParts[1];
                    }
                    else {
                        document = documentParts[0];
                    }

                    documents.push(document);
                    if (i + 1 === dialogState.files?.length) {
                        accept({ files: documents, code: dialogState.code });
                    }
                }
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };

            };

        }
        else {
            accept({ files: [], code: dialogState.code });
        }
    }

    const OrderDocumentUploadDialogFooter = () => {
        return (<div>
            <Button label={tawreedTranslationService.translate('lbl_cancel')} icon="pi pi-times" onClick={() => { setDialogState({ files: [], code: undefined }); hide() }} className="p-button-text" /*disabled={dialogState.loading}*/ />
            <Button onClick={() => { setDialogState({ files: [], code: undefined }); returnFile(); }} label={tawreedTranslationService.translate('lbl_accept')} icon="pi pi-check"
                disabled={requireOtp ? (!dialogState || !dialogState.code || !dialogState.files?.length) : (payThroughUs ? (!dialogState.files?.length) : false)}
            />
        </div>);
    }
    const itemTemplate = (el: File) => {
        return (
            // <div onClick={() => { setSelectedItem(item) }} className={`flex flex-wrap p-2 align-items-center gap-3 ${selectedItem && selectedItem.storeProductRawMatchId === item.storeProductRawMatchId ? "selectedBonus" : ""}`}>
            <div className={`flex flex-wrap p-2 align-items-center gap-3`}>
                <div className="flex-1 flex flex-column gap-2 xl:mr-8">
                    <span className="font-bold">{el.name}</span>
                </div>
                <div>
                    <span className="font-bold text-900"> <Button onClick={(e) => setDialogState({ ...dialogState, files: (dialogState.files ?? []).filter((el1: File) => el1.name !== el.name) })} icon={PrimeIcons.TRASH} className='p-button-danger'></Button>
                    </span>
                </div>
            </div>
        );
    };
    return (
        <Dialog style={{ maxWidth: '512px', minWidth: '55%' }} header={requireOtp ? <span style={{ fontSize: '13px', fontWeight: 'bold' }}>{tawreedTranslationService.translate('lbl_otp_message')}</span>
            : tawreedTranslationService.translate('lbl_document_files_add_file')}
            visible={visible} footer={<OrderDocumentUploadDialogFooter />} onHide={hide} draggable={false} position="top" closable={false} closeOnEscape={false}>
            <form className="formgrid grid" >
                <React.Fragment>
                    {requireOtp &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <OtpInput
                                inputStyle={{ width: '100%', height: '50px' }}
                                numInputs={4}
                                isInputNum
                                separator={<span>-</span>}
                                value={dialogState.code}
                                onChange={(e: any) => {
                                    setDialogState({ ...dialogState, code: e })
                                }}
                            />
                        </div>
                    }
                    <TawreedFormField title="lbl_files_file" className="field col-12 align" name="file">
                        <TawreedInputFile render='standalone' name="file" onChange={
                            (e: TawreedFile | undefined) => setDialogState({ ...dialogState, files: e && e.value instanceof File ? [...dialogState.files ?? [], e.value] : dialogState.files })
                        } value={undefined} />
                    </TawreedFormField>
                    <OrderList className='col-12' listStyle={{ minHeight: 'auto' }} dataKey="id" value={dialogState.files ?? []} onChange={(e) => { }} itemTemplate={itemTemplate} header={tawreedTranslationService.translate("lbl_files")} ></OrderList>


                </React.Fragment>
            </form>
        </Dialog>
    )
}
