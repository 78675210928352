import { PrimeIcons } from "primereact/api";
import { Panel } from "primereact/panel";
import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { TawreedAction, TawreedActionName } from "../../../../../common/actions";
import { TawreedCalendar, TawreedForm, TawreedFormField, TawreedFormMode, TawreedFormSubmitAction, TawreedFormSubmitCallback, TawreedFormSubmitProps, TawreedInputNumber, TawreedInputSwitch, TawreedInputText, TawreedInputTextarea } from "../../../../../common/components/form";
import { tawreedTranslationService } from "../../../../../common/translations";
import { PartnerAutoCompleteComponent } from "../../../../partners";
import { PartnerType } from "../../../../partners/data";
import { Routes } from "../../../../../router";
import { ProductAutoCompleteComponent } from "../../../products/presentaion/components";
import { StoreProductDto, StoreProductsService, priceListDto, PriceSchemeService } from "../../domain";
import { PriceListServcie } from "../../domain/services/price-list.service";
import { PriceListForm } from "./price-list-form";
import { toastService } from "../../../../notifications/domain";
import { GlobalContext } from "../../../../../context";
import { ProductDto } from "../../../products/domain";
import { CommissionService } from "../../../../partners/domain";
import { resolve } from "path";
import { ProductDialog } from "./product-dialog";
import { Button } from "primereact/button";
import { AuthUtils } from "../../../../auth/domain";

export const StoreProductForm: FC = (_props) => {
    const { id } = useParams<{ id: string | undefined }>();
    const [popUp, setPopUp] = useState(false);

    const defaultRef = useRef(null);
    const creditRef = useRef(null);
    const ref = useRef<{ submit: () => void }>();

    let array: any[] = [];

    // di
    const service: StoreProductsService = useMemo(() => new StoreProductsService(), []);

    const priceListService: PriceListServcie = useMemo(() => new PriceListServcie(), []);

    const priceSchemeService: PriceSchemeService = useMemo(() => new PriceSchemeService(), []);
    const commissionService: CommissionService = useMemo(() => new CommissionService(), []);

    const { auth: { user }, constants: { constants } } = React.useContext(GlobalContext);


    const [loading, setLoading] = useState<boolean>(false);
    const [mode, setMode] = React.useState<TawreedFormMode>("Create");
    const [data, setData] = useState<StoreProductDto>({
        active: true,
    });
    const [priceLists, setPriceLists] = useState<Array<priceListDto>>([]);

    const history = useHistory();


    useEffect(() => {
        let mounted = true;
        if (id) {
            if (mounted) {
                setMode("Edit");
                setLoading(true);
            }
            priceListService.getAll().then((res) => {
                setPriceLists(res);
            });
            service
                .getDetails(Number.parseInt(id))
                .then((res) => {
                    if (mounted) {
                        setLoading(false);
                        setData(res);
                    }
                })
                .catch(() => {
                    if (mounted) {
                        setLoading(false);
                    }
                });
        } else {
            if (mounted) {
                setMode("Create");
                priceListService.getAll().then((res) => {
                    setPriceLists(res);
                });
            }
        }
        if (user?.roleCode === 'STORE_ADMIN')
            setData({ ...data, store: { partnerId: user.partnerId } })
        return () => {
            mounted = false;
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, service, priceListService]);

    const getSellingPrice = (el: any) => {

        let discountType = 'fixed';
        if (el.discountType === 'percentage')
            discountType = 'percentage';
        if (el.discountType === 'bonus')
            discountType = 'bonus';
        let discountValue = 0;
        if (el.discountValue)
            discountValue = el.discountValue;
        let sellingPrice = el.price!;
        sellingPrice -= (discountType === 'fixed' ? discountValue : (el.price! * discountValue / 100));
        if (discountType === 'bonus')
            sellingPrice = el.price! - (el.price! * (discountValue / (discountValue + 100)));
        return sellingPrice;

    }

    const onCreateOrSave = async (data: StoreProductDto, cb: TawreedFormSubmitCallback<StoreProductDto>): Promise<void> => {
        //@ts-ignore
        defaultRef.current.click();
        //@ts-ignore
        creditRef.current.click();
        let ok = false;
        array.forEach((el) => {
            if (el.price && el.price > 0)
                ok = true;
            if (!el.discountType)
                el.discountType = "fixed"
            if (!el.discountValue)
                el.discountValue = 0
        })

        if (!ok) {
            toastService.show({ detail: tawreedTranslationService.translate('lbl_price_list_error'), severity: 'error' });
            return new Promise<void>((resolve, reject) => {
                reject();
            });
        }
        ok = true;
        setLoading(true);

        for await (const item of array) {
            if (item.price && item.price > 0) {
                console.log(item);
                const value = getSellingPrice(item);
                console.log(value);
                const result = await commissionService.checkPrice({ partnerId: data.store?.partnerId, priceListId: item.priceListId, value: getSellingPrice(item) });
                if (!result)
                    ok = false;
            }
        }
        if (!ok) {
            toastService.show({ detail: tawreedTranslationService.translate('lbl_price_list_commission_error'), severity: 'error' });
            return new Promise<void>((resolve, reject) => {
                setLoading(false);
                reject();
            });

        }
        data.expireDate?.setTime(data.expireDate.getTime() + (3 * 60 * 60 * 1000));
        return service
            .createOrUpdate(data)
            .then(async (res) => {
                for await (const priceSchemeDto of array) {
                    if (priceSchemeDto.price > 0)
                        await priceSchemeService.createOrUpdate({ ...priceSchemeDto, storeProductId: res.storeProductId })
                }
                setLoading(false);
                if (!data.storeProductId && res.storeProductId) {
                    history.replace(Routes.StoreProductsDetails.replace(":id", res.storeProductId.toString()));
                }
                cb(res);
            })
            .catch((err) => {
                setLoading(false);
                cb(err);
            });
    };


    const onSubmit: TawreedFormSubmitProps<StoreProductDto> = {
        showButton: true,
        resetOnSubmit: false,
        onSubmit: new TawreedFormSubmitAction<StoreProductDto>(TawreedActionName.StoreProductsSubmit, "lbl_save", PrimeIcons.SAVE, onCreateOrSave),
    };
    console.log(data);


    const onDelete = () => {
        setLoading(true);
        return service
            .delete(data.storeProductId!)
            .then((res) => {
                setLoading(false);
                if (res) {
                    history.replace(Routes.StoreProductsSearch);
                }
            })
            .catch((err) => {
                setLoading(false);
                throw err;
            });
    };

    const createNew = () => {
        return new Promise<void>((res, rej) => {
            history.push(Routes.StoreProductsNew)
            res()
        })
    }

    const actions = [new TawreedAction(TawreedActionName.StoreProductsDelete, "statefull", "lbl_delete", PrimeIcons.TRASH, onDelete, "p-button-danger", "confirmDelete")];
    if (id && actions.length === 1) {
        actions.push(new TawreedAction(TawreedActionName.StoreProductsSubmit, "statefull", "lbl_new", PrimeIcons.PLUS, createNew))
    }
    return (
        <React.Fragment>


            <TawreedForm customeTitle={data.titleEn ? (AuthUtils.current().language === 'en' ? data.titleEn : data.titleAr) + " - " + constants?.productCategories.find(el => el.id === (data.product?.categoryId ? data.product?.categoryId : data.categoryId))?.label : ''} ref={ref} title="lbl_store_product" dataKey="storeProductId" data={data} useReset submit={onSubmit} loading={loading} mode={mode} useBack=".." actions={actions} className="grid form-grid">
                <Panel header={tawreedTranslationService.translate("lbl_general_information")} className="col-12">
                    <div className="grid">

                        {user?.roleCode !== 'STORE_ADMIN' &&
                            <React.Fragment>
                                <TawreedFormField name="store" title="lbl_store_name" className="field col-12 md:col-6">
                                    <PartnerAutoCompleteComponent
                                        partnerType={PartnerType.Store}
                                        autoCompleteName="store"
                                        autoCompleteField="partnerName"
                                        autoCompleteRules={{ required: "msg_field_is_required" }}
                                        autoCompleteDisabled={data.storeProductId != null && true}
                                        autoCompleteOnSelect={(e) => {
                                            setData({ ...data, store: e.value });
                                        }}
                                        autoCompleteRender="form" />
                                </TawreedFormField>
                                <TawreedFormField name="active" title="lbl_product_active" className="field-checkbox col-12 md:col-6" flip>
                                    <TawreedInputSwitch name="active" render="form" />
                                </TawreedFormField>
                            </React.Fragment>

                        }
                        <React.Fragment>
                            <TawreedFormField name="product" title="lbl_store_products_product_id" className="field col-12 md:col-6">
                                <React.Fragment>
                                    <ProductAutoCompleteComponent
                                        storeId={data.store?.partnerId}
                                        autoCompleteName="product"
                                        autoCompleteRules={{ required: "msg_field_is_required" }}
                                        autoCompleteDisabled={data.store ? (data.storeProductId != null && true) : true}
                                        autoCompleteRender="form"
                                        autoCompleteOnSelect={e => {
                                            const p = e.value as ProductDto;
                                            console.log("ProductDto", p);
                                            if (p) {
                                                setData({
                                                    ...data,
                                                    storeItemId: p.internationalCode,
                                                    product: p,
                                                    titleAr: p.displayName,
                                                    titleEn: p.displayName,
                                                    tax: p.productTax,
                                                    price: p.price,
                                                });
                                            } else {
                                                setData({
                                                    ...data,
                                                    product: undefined,
                                                    titleAr: undefined,
                                                    titleEn: undefined,
                                                    tax: undefined,
                                                    price: undefined,
                                                    storeItemId: undefined,
                                                });
                                            }
                                        }} />
                                    <div className="p-2 font-bold">
                                        {
                                            data.storeProductId == null &&
                                            <NavLink to={"#"} onClick={() => setPopUp(true)}>{tawreedTranslationService.translate("lbl_store_product_browse")}</NavLink>

                                        }

                                    </div>
                                </React.Fragment>
                            </TawreedFormField>
                        </React.Fragment>
                        {user?.roleCode === "STORE_ADMIN" &&
                            <TawreedFormField name="active" title="lbl_product_active" className="field-checkbox col-12 md:col-6" flip>
                                <TawreedInputSwitch name="active" render="form" />
                            </TawreedFormField>}
                        <TawreedFormField name="storeItemId" title="lbl_store_product_item_id" className="field col-12 md:col-6">
                            <React.Fragment>
                                <TawreedInputText placeholder="" name="storeItemId" rules={{ required: "msg_field_is_required" }} render="form" />
                                <p >{tawreedTranslationService.translate("lbl_store_product_store_hint")}</p>

                            </React.Fragment>
                        </TawreedFormField>

                        <TawreedFormField name="expireDate" title="lbl_store_product_expire_date" className="field col-12 md:col-6">
                            <TawreedCalendar minDate={new Date()} render="form" name="expireDate" rules={data.product?.categoryId === 1 ? { required: 'msg_field_is_required' } : {}} />
                        </TawreedFormField>

                        <TawreedFormField name="tax" title="lbl_store_products_tax" className="field col-12 md:col-6">
                            <TawreedInputNumber suffix={" %"} mode={"decimal"} name="tax" rules={{ required: "msg_field_is_required" }} render="form" />
                        </TawreedFormField>
                        {/* <TawreedFormField name="minimumOrderQuantity" title={"lbl_min_order_qty"} className="field col-12 md:col-6">
                            <TawreedInputNumber name="minimumOrderQuantity" render="form" onChange={(e) => {
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="maximumOrderQuantity" title={"lbl_max_order_qty"} className="field col-12 md:col-6">
                            <TawreedInputNumber name="maximumOrderQuantity" render="form" onChange={(e) => {
                            }} />
                        </TawreedFormField> */}
                        <TawreedFormField name="titleAr" title="lbl_store_product_title_ar" className="field col-12 md:col-6">
                            <TawreedInputText name="titleAr" render="form" rules={{
                                required: 'msg_field_is_required',
                                validate: (value) => {
                                    var trim_value = value.trim();
                                    if (trim_value.length == 0) {
                                        return tawreedTranslationService.translate('lbl_whiteSapce_field');
                                    }

                                }
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="titleEn" title="lbl_store_product_title_en" className="field col-12 md:col-6">
                            <TawreedInputText name="titleEn" render="form" rules={{
                                required: 'msg_field_is_required',
                                validate: (value) => {
                                    var trim_value = value.trim();
                                    if (trim_value.length == 0) {
                                        return tawreedTranslationService.translate('lbl_whiteSapce_field');
                                    }

                                }
                            }} />
                        </TawreedFormField>
                        <TawreedFormField name="description" title="lbl_store_product_description" className="field col-12 md:col-12">
                            <TawreedInputTextarea name="description" render="form" />
                        </TawreedFormField>
                    </div>
                </Panel>
            </TawreedForm>
            {data.product && <Panel header={tawreedTranslationService.translate('lbl_price_scheme_price_list_name')} >
                {
                    priceLists.map((el, idx) => {
                        return (

                            <PriceListForm
                                name={el.priceListName!}
                                onSavePriceList={(data) => {
                                    const idx = array.findIndex((el) => el.priceListId === data.priceListId)
                                    if (idx >= 0) {
                                        array[idx] = data;
                                    }
                                    else
                                        array.push(data);
                                }}
                                ref1={idx === 0 ? defaultRef : creditRef}
                                storeProductId={id ? +id : -1}
                                priceListId={el.priceListId}
                                price={data.price}
                                key={idx}
                                loading={loading}
                            ></PriceListForm>

                        )
                    })
                }
            </Panel >}
            {popUp && <ProductDialog visible={popUp} isDiscount={false} hide={() => setPopUp(false)} accept={(selectedData) => {
                setData({
                    ...data,
                    product: selectedData,
                    titleAr: selectedData.productName,
                    titleEn: selectedData.productNameEn,
                    tax: selectedData.productTax,
                    price: selectedData.price,
                    storeItemId: selectedData.internationalCode,
                })
                setPopUp(false);
            }}></ProductDialog>}
        </React.Fragment >
    );
};
