import {PaginatedRequestParams, PaginatedResponse} from "../../../../../common/pagination";
import {StoreProductsRepository} from "../../data";
import {StoreProductDto, StoreProductSearchSimilarQuery} from "../dtos";

export class StoreProductsService {

    private readonly repository: StoreProductsRepository;

    constructor() {
        this.repository = new StoreProductsRepository();
    }

    public search(request: PaginatedRequestParams): Promise<PaginatedResponse<StoreProductDto>> {
        return this.repository.search(request);
    }

    public searchSimilar(e: StoreProductSearchSimilarQuery): Promise<PaginatedResponse<StoreProductDto>> {
        return this.repository.searchSimilar(e);
    }
    public searchSimilarWithPrice(e: StoreProductSearchSimilarQuery,request: PaginatedRequestParams): Promise<PaginatedResponse<StoreProductDto>> {
        return this.repository.searchSimilarWithPrice(e,request);
    }


    public createOrUpdate(form: StoreProductDto): Promise<StoreProductDto> {
        return this.repository.createOrUpdate(form);
    }

    public delete(id: number): Promise<boolean> {
        return this.repository.delete(id);
    }

    public deleteAll(ids: number[]): Promise<boolean> {
        return this.repository.deleteAll(ids);
    }

    public getDetails(id: number): Promise<StoreProductDto> {
        return this.repository.getDetails(id);
    }

    
    public activateAll(ids: StoreProductDto[]): Promise<boolean> {
        return this.repository.activateAll(ids);
    }

    public deactivateAll(ids: StoreProductDto[]): Promise<boolean> {
        return this.repository.deactivateAll(ids);
    }

}
