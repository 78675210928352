import React from "react";
import { Panel } from "primereact/panel";
import { setFocus, TawreedFormField, TawreedInputText, TawreedNavLink } from "../../../../../common/components/form";
import { tawreedTranslationService } from "../../../../../common/translations";
import { PartnerDto } from "../../../../partners/domain";
import { PartnerAutoCompleteComponent } from "../../../../partners";
import { PartnerType } from "../../../../partners/data";
import { PartnerFiles } from "../../../../partners/presentaion/components/partner-files";

export interface CartFormCustomerProps {
    className?: string;
    initial?: PartnerDto;
    onSelect: (p: PartnerDto) => void;
    disabled: boolean;
}


export const CartFormCustomer: React.FC<CartFormCustomerProps> = (props) => {

    return (
        <Panel header={tawreedTranslationService.translate('lbl_sales_cart_customer_information')} className="col-12">
            <div className="grid">
                <TawreedFormField className="field col-12 md:col-6" name="customer" title="lbl_sales_carts_customer_partner_name">
                    <PartnerAutoCompleteComponent partnerType={PartnerType.Customer}
                        active={true}
                        autoCompleteName="customer"
                        autoCompleteRules={{ required: 'msg_field_is_required' }}
                        autoCompleteField="partnerName"
                        autoCompleteOnSelect={e => {
                            props.onSelect(e.value);
                            setFocus('storeProduct');
                        }}
                        autoCompleteDisabled={props.disabled}
                        autoCompleteRender="form" />
                </TawreedFormField>

                <TawreedFormField className="field col-12 md:col-6" name="customer" title="lbl_partners_contact_mobile">
                    <TawreedInputText name="customer" render="form-nested" nested="contact.mobileNo" disabled readOnly />
                </TawreedFormField>

                <TawreedFormField className="field col-12" name="customer" title="lbl_partners_address_address">
                    <TawreedInputText name="customer" render="form-nested" nested="address.address" disabled readOnly />
                </TawreedFormField>
                {props.initial && props.initial.files && <PartnerFiles render="standalone" partnerType={props.initial.partnerType!} businessType={props.initial.businessType!} value={props.initial.files} disabled />}
                {props.initial && props.initial.address &&
                    <TawreedFormField name="customer" className="field col-12" title="">
                        <TawreedNavLink render="standalone" name="address.location" to={() => props.initial?.address?.location || ''}>
                            {tawreedTranslationService.translate('lbl_partners_address_location')}
                        </TawreedNavLink>
                    </TawreedFormField>}
            </div>
        </Panel>
    );
}
